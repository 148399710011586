import { Controller } from "stimulus"
import Dropzone from "dropzone"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    maxFiles: Number,
    maxFilesSize: Number,
  }

  messageWording() {
    let desc = "a file";
    if (this.maxFilesValue > 1) { desc = "files" }
    return(
      `Click here to select ${desc}. You can also drag & drop ${desc}.`
    )
  };

  connect() {
    Dropzone.autoDiscover = false
    this.inputTarget.disable = true
    this.inputTarget.style.display = "none"
    const dropzone = new Dropzone(this.element, {
      url: '/',
      maxFiles: this.maxFilesValue,
      maxFilesize: this.maxFilesSize,
      autoQueue: false,
      dictDefaultMessage: this.messageWording()
    });

    dropzone.on("addedfile", file => {
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url, this)
          upload.create((error, blob) => {
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.name = this.inputTarget.name
            this.hiddenInput.value = blob.signed_id
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            dropzone.emit("complete", file)
          });
        }
      }, 500)
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  };

  directUploadDidProgress(event) {
    const percentageMeter = this.inputTarget.closest('.dropzone').querySelector('.dz-progress .dz-upload');
    if (percentageMeter) {
      let percentageComplete = ((event.loaded / event.total) * 100) + '%';

      percentageMeter.style.width = percentageComplete
    }
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }
}
